import * as React from 'react'
import { graphql } from 'gatsby'
import EntriesPage from '../components/entries'
import { details } from '../styles/entries.module.css'

const WorkDataHandler = (data) => {
  const entryData = data.childMdx.frontmatter;
  const startDate = new Date(entryData.start);
  const endDate = new Date(entryData.end);
  const endDateStr = isNaN(endDate.getTime()) ? "Present" : `${endDate.toLocaleString('default', { month: 'long' })}, ${endDate.getFullYear()}`;
  const startDateStr = `${startDate.toLocaleString('default', { month: 'long' })}, ${startDate.getFullYear()}`;
  return (
    <div className={details}>
      <p className="lead">{entryData.role}</p>
      <p className="lead">{startDateStr} - {endDateStr}</p>
    </div>
  );
};

const WorkPage = ({ data }) => (
  <EntriesPage title="Work" entries={data.allFile.nodes} extraHandler={WorkDataHandler} />
);

export const query = graphql`
{
    allFile(
      filter: {sourceInstanceName: {eq: "work"}}
      sort: {order: DESC, fields: childrenMdx___frontmatter___start}
    ) {
      nodes {
        childMdx {
          body
          slug
          frontmatter {
            title
            start
            end
            role
          }
        }
      }
    }
  }  
`;

export default WorkPage
